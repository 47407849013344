$(document).on('turbolinks:load', () => {
  let default_starts = $('#datepicker_starts_date').attr('value');
  let default_ends = $('#datepicker_ends_date').attr('value');
  let datepickerConfig = {
    autoApply: false,
    maxDate: moment(),
  };

  function clearDatepicker() {
    $('input[name="datepicker"]').val('');
  }

  $('input[name="datepicker"]').daterangepicker(datepickerConfig);
  clearDatepicker();
  if (default_starts.length && default_ends.length) {
    $('input[name="datepicker"]').data('daterangepicker').setStartDate(moment(default_starts).format("MM/DD/YYYY"));
    $('input[name="datepicker"]').data('daterangepicker').setEndDate(moment(default_ends).format("MM/DD/YYYY"));
  }
  $('input[name="datepicker"]').on('cancel.daterangepicker', function(ev, picker) {
    clearDatepicker();
  });


  $('[data-search-btn]').click(_ => {
    let datepicker = $('input[name="datepicker"]');
    let datepickerData = datepicker.data('daterangepicker');
    let queryMap = {};
    if (datepicker.val().length) {
      queryMap["starts"] = datepickerData.startDate.format("YYYY-MM-DD");
      queryMap["ends"] = datepickerData.endDate.format("YYYY-MM-DD");
    }

    let selectedLabels = [];
    $.map($('input[name="index-stories-label"]:checked:enabled'), (e, i) => {
      selectedLabels.push(e.getAttribute('value'));
    });
    if (selectedLabels.length > 0) {
      queryMap["labels"] = selectedLabels.join(",");
    }

    let query = $.param(queryMap);
    window.location.href = `/stats/?${query}`;
  });

  $('[data-search-all-btn]').click(_ => {
    window.location.href = `/stats/?actives=true`;;
  });

  function redirectToSearchWithLabel(e, isAcceptedByLabel) {
    e.preventDefault();
    let domId = 'not-accepted-by-label-chart';
    let queryMap = {};

    if (isAcceptedByLabel) {
      domId = 'accepted-by-label-chart';
      queryMap["states"] = "accepted";
    }

    const chart = Chartkick.charts[domId].getChartObject();
    let firstPoint = chart.getElementsAtEvent(e)[0];
    if (firstPoint) {
      let label = chart.data.labels[firstPoint._index].replace(/ \(\d+\)/, "");
      if (label != "No Label") {
        queryMap["labels"] = label;
      }
      let datepicker = $('input[name="datepicker"]');
      let datepickerData = datepicker.data('daterangepicker');
      if (datepicker.val().length) {
        queryMap["starts"] = datepickerData.startDate.format("YYYY-MM-DD");
        queryMap["ends"] = datepickerData.endDate.format("YYYY-MM-DD");
      }

      window.location.href = `/jira-search?${$.param(queryMap)}`;
    }
  }

  $('#accepted-by-label-chart > canvas').click(e => {
    redirectToSearchWithLabel(e, true);
  });

  $('#not-accepted-by-label-chart > canvas').click(e => {
    redirectToSearchWithLabel(e, false);
  });

  $('.index-stories-label-follower').click(e => {
    label = e.target.getAttribute("value");
    let queryMap = {};
    queryMap["labels"] = label;

    let datepicker = $('input[name="datepicker"]');
    let datepickerData = datepicker.data('daterangepicker');
    if (datepicker.val().length) {
      queryMap["starts"] = datepickerData.startDate.format("YYYY-MM-DD");
      queryMap["ends"] = datepickerData.endDate.format("YYYY-MM-DD");
    }

    window.location.href = `/?${$.param(queryMap)}`;
  })

  // SEARCH
  $('[data-search-stories-btn]').click(_ => {
    let queryMap = {};

    let inputName = $('[data-search-stories-name-input]').val()
    if (inputName.length) {
      queryMap["name"] = inputName;
    }

    let selectedLabels = [];
    $('input[data-search-stories-label]:checked:enabled').each((i, e) => {
      selectedLabels.push(e.getAttribute('value'));
    });
    if (selectedLabels.length) {
      queryMap["labels"] = selectedLabels.join(",");
    }

    let selectedStates = [];
    $('input[data-search-stories-state]:checked:enabled').each((i, e) => {
      selectedStates.push(e.getAttribute('value'));
    });
    if (selectedStates.length) {
      queryMap["states"] = selectedStates.join(",")
    }

    let datepicker = $('input[name="datepicker"]');
    let datepickerData = datepicker.data('daterangepicker');
    if (datepicker.val().length) {
      queryMap["starts"] = datepickerData.startDate.format("YYYY-MM-DD");
      queryMap["ends"] = datepickerData.endDate.format("YYYY-MM-DD");
    }

    window.location.href = `/jira-search?${$.param(queryMap)}`;
  });

  // PRIORITY
  $('[data-priority-stories-btn]').click(_ => {
    let queryMap = {};

    let inputName = $('[data-search-stories-name-input]').val()
    if (inputName.length) {
      queryMap["name"] = inputName;
    }

    let selectedLabels = [];
    $('input[data-search-stories-label]:checked:enabled').each((i, e) => {
      selectedLabels.push(e.getAttribute('value'));
    });
    if (selectedLabels.length) {
      queryMap["labels"] = selectedLabels.join(",");
    }

    let selectedStates = [];
    $('input[data-search-stories-state]:checked:enabled').each((i, e) => {
      selectedStates.push(e.getAttribute('value'));
    });
    if (selectedStates.length) {
      queryMap["states"] = selectedStates.join(",")
    }

    let datepicker = $('input[name="datepicker"]');
    let datepickerData = datepicker.data('daterangepicker');
    if (datepicker.val().length) {
      queryMap["starts"] = datepickerData.startDate.format("YYYY-MM-DD");
      queryMap["ends"] = datepickerData.endDate.format("YYYY-MM-DD");
    }

    window.location.href = `/priority?${$.param(queryMap)}`;
  });
   
  //Drag and drop Priority
  var table = document.getElementById('priority-table');

  var emptyArray = [];

  for (var r = 1, n = table.rows.length; r < n; r++) {
    var element = table.rows[r];
    emptyArray.push(element.id);

  }
 
  $('#priority-table tbody').sortable({
    axis: 'y',
    cursor: 'move',
    containment: '.table-responsive',

    sort: function(e, ui) {
      ui.item.addClass('active-item-shadow');
    },
    stop: function(e, ui) {
      ui.item.removeClass('active-item-shadow');
      ui.item.children('td').effect('highlight', {}, 1000);
    },

    
    update: function(event, ui) {
       
      var changedIds = $(this).sortable("toArray")

      $.ajax({
        url: 'jira-change-priority',
        method: 'POST',
        data: {
          first_element: emptyArray,
          second_element: changedIds
        },
        success: function(data){
          emptyArray = changedIds;
        },
        error: function(data) { 
          alert("Ha habido un problema con la petición");  
        }
      });
    }
  });
});
